<div>
    <form class=" pt-1" [formGroup]="individualForm">
        <p-tabView (onChange)="resetForm()" [(activeIndex)]="activeIndex"  class="custom-tabview secondary w-full">
            <p-tabPanel header="Select Existing Individual ">
                <div class="d-flex flex-col gap-y-30px min-h-370px mt-3">
                    <div class="row gap-y-24px justify-content-center">
                        <div class="w-full">
                            <span class="p-float-label cus-float-label">
                                <p-dropdown class="w-full cmn-dropdown filter-input" (onChange)="showSlot(selectedInd)"
                                    [(ngModel)]="selectedInd" [ngModelOptions]="{standalone: true}"
                                    optionLabel="indName" [options]="nonInviteIndList" [filter]="true"
                                    [showClear]="true"
                                    (onClear)="clearForm()"
                                    [filterFields]="['firstName', 'lastName', 'studentID', 'indID_Client', 'DBkey']"
                                    placeholder="Search Here">
                                </p-dropdown>
                                <label for="Source-float-label">All Known Individuals</label>
                            </span>
                        </div>
                    </div>

                    <div class="row gap-y-24px justify-content-center cmn-floating-section">
                        <label class="main-label">Subject Detail</label>
                        <div class="col-6">
                            <span class="p-float-label cus-float-label">
                                <input class="w-full" pInputText [required]="true" formControlName="firstName"
                                    readonly />
                                <label>First Name</label>
                            </span>
                            <div *ngIf="individualForm.get('firstName').invalid && individualForm.get('firstName').touched"
                                class="invalid-feedback">First Name is required</div>
                        </div>
                        <div class="col-6">
                            <span class="p-float-label cus-float-label">
                                <input class="w-full" pInputText [required]="true" formControlName="lastName"
                                    readonly />
                                <label>Last Name</label>
                            </span>
                            <div *ngIf="individualForm.get('lastName').invalid && individualForm.get('lastName').touched"
                                class="invalid-feedback">Last Name is required</div>
                        </div>
                        <div class="col-6">
                            <span class="p-float-label cus-float-label">
                                <input class="w-full" pInputText [required]="true" formControlName="studentID"
                                    readonly />
                                <label>ID at Organization</label>
                            </span>
                            <div *ngIf="individualForm.get('studentID').invalid && individualForm.get('studentID').touched"
                                class="invalid-feedback">ID at Organization is required</div>
                        </div>
                        <div class="col-6">
                            <span class="p-float-label cus-float-label">
                                <input class="w-full" pInputText [required]="true" formControlName="indID_Client"
                                    readonly />
                                <label>ID at Studio</label>
                            </span>
                            <div *ngIf="individualForm.get('indID_Client').invalid && individualForm.get('indID_Client').touched"
                                class="invalid-feedback">ID at Studio is required</div>
                        </div>
                        <div class="col-6">
                            <span class="p-float-label cus-float-label">
                                <input class="w-full" pInputText formControlName="email"
                                    [ngClass]="{'invalid-input': individualForm.get('email').invalid}" />
                                <label>Email ID</label>
                            </span>
                            <div *ngIf="individualForm.get('email').errors?.['email']" class="invalid-feedback">Invalid
                                email format</div>
                        </div>
                        <div class="col-6">
                            <span class="p-float-label cus-float-label">
                                <input class="w-full" pInputText [required]="true" formControlName="mobilePhone" />
                                <label>Mobile Phone</label>
                            </span>
                            <div *ngIf="individualForm.get('mobilePhone').invalid && individualForm.get('mobilePhone').touched"
                                class="invalid-feedback">Mobile Phone is required</div>
                        </div>
                    </div>

                    <div class="col-12 mx-auto">
                        <div class="d-flex gap-5 max-w-400px mx-auto col-6">
                            <p-button type="button" class="w-full cmn-btn-secondary rounded-full"
                                (onClick)="closeDialog()" label="Cancel" [outlined]="true"></p-button>
                            <p-button (onClick)="onSubmit()" class="w-full cmn-btn-primary rounded-full"
                                [disabled]="!isShowDetails" label="Save"></p-button>
                        </div>
                    </div>
                </div> 
            </p-tabPanel>
            <p-tabPanel header="Add New Individual">
                <div class="d-flex flex-col gap-y-30px min-h-370px mt-3">
                    <div class="row gap-y-24px justify-content-center cmn-floating-section">
                        <label class="main-label">New Individual</label>
                        <div class="col-6">
                            <span class="p-float-label cus-float-label">
                                <input class="w-full" pInputText [required]="true" formControlName="firstName"/>
                                <label>First Name</label>
                            </span>
                            <div *ngIf="individualForm.get('firstName').invalid && individualForm.get('firstName').touched"
                                class="invalid-feedback">First Name is required</div>
                        </div>
                        <div class="col-6">
                            <span class="p-float-label cus-float-label">
                                <input class="w-full" pInputText [required]="true" formControlName="lastName"/>
                                <label>Last Name</label>
                            </span>
                            <div *ngIf="individualForm.get('lastName').invalid && individualForm.get('lastName').touched"
                                class="invalid-feedback">Last Name is required</div>
                        </div>
                        <div class="col-6">
                            <span class="p-float-label cus-float-label">
                                <input class="w-full" pInputText [required]="true" formControlName="studentID"/>
                                <label>ID at Organization</label>
                            </span>
                            <div *ngIf="individualForm.get('studentID').invalid && individualForm.get('studentID').touched"
                                class="invalid-feedback">ID at Organization is required</div>
                        </div>
                        <div class="col-6">
                            <span class="p-float-label cus-float-label">
                                <input class="w-full" pInputText [required]="true" formControlName="indID_Client"/>
                                <label>ID at Studio</label>
                            </span>
                            <div *ngIf="individualForm.get('indID_Client').invalid && individualForm.get('indID_Client').touched"
                                class="invalid-feedback">ID at Studio is required</div>
                        </div>
                        <div class="col-6">
                            <span class="p-float-label cus-float-label">
                                <input class="w-full" pInputText formControlName="email"
                                    [ngClass]="{'invalid-input': individualForm.get('email').invalid}" />
                                <label>Email ID</label>
                            </span>
                            <div *ngIf="individualForm.get('email').errors?.['email']" class="invalid-feedback">Invalid
                                email format</div>
                        </div>
                        <div class="col-6">
                            <span class="p-float-label cus-float-label">
                                <input class="w-full" pInputText [required]="true" formControlName="mobilePhone" />
                                <label>Mobile Phone</label>
                            </span>
                            <div *ngIf="individualForm.get('mobilePhone').invalid && individualForm.get('mobilePhone').touched"
                                class="invalid-feedback">Mobile Phone is required</div>
                        </div>
                    </div>

                    <div class="col-12 mt-auto">
                        <div class="d-flex gap-5 max-w-400px mx-auto col-6">
                            <p-button type="button" class="w-full cmn-btn-secondary rounded-full"
                                (onClick)="closeDialog()" label="Cancel" [outlined]="true"></p-button>
                            <p-button (onClick)="onSubmit(true)" class="w-full cmn-btn-primary rounded-full"
                                label="Save"></p-button>
                        </div>
                    </div>
                </div> 
            </p-tabPanel>
        </p-tabView>
    </form>
</div>